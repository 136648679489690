import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {getError} from '@/api/index'
import vueTouch from 'kim-vue-touch'
 
Vue.use(vueTouch)
//公共css文件
import "@/assets/css/common.css";
import "vant/lib/index.css";
import '@/assets/css/iconfont.css'
import hljs from 'highlight.js'
//样式文件,我选的是atom-one-dark-reasonable样式 可以通过highlight.js/styles 选择其他css
import 'highlight.js/styles/atom-one-dark-reasonable.css'
// import 'highlight.js/styles/atom-one-dark.css'
import hevueImgPreview from 'hevue-img-preview'
Vue.use(hevueImgPreview)
// 这里 挂载highlight
Vue.directive('highlight',function (el) {
    let element = el.querySelectorAll('pre code');
    element .forEach((block)=>{
      hljs.highlightBlock(block)
    })
})


//淘宝无线适配
// import "@/assets/js/flexible";
import vant from 'vant'
Vue.use(vant)
Vue.config.productionTip = false;
Vue.directive('preventReclick', {
  inserted (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 1000)
      }
    })
  }
})
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
const errorHandler =  async(err, vm, info)=>{
  if (info !== 'v-on handler (Promise/async)' && err !== 'notToken') {
    console.log(err,'err');
     getError({msg:err['message'],info,from:window.location.href},'error','全局错误捕捉')  
  }
}
Vue.config.errorHandler = errorHandler;
Vue.prototype.$throw = (error)=> errorHandler(error,this)