import Vue from "vue";
import VueRouter from "vue-router";
import {ip_login,get_weixin_lohinget,get_gzh_code,getError} from '@/api/index'
import { isWeixin, getLocationParams, urldecode, removeURLParameter, isWeixinBroswer, urlencode, routeParams,setApp, setData, getData } from '@/utils/tool'
import wx from 'weixin-js-sdk'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import('@/views/HomeView.vue'),
    meta: {
      title: "首页"
    }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import('@/views/Privacy.vue'),
    meta: {
      title: "隐私协议"
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/views/Login.vue'),
    meta: {
      title: "登录",
      keepAlive: true
    }
  },
  {
    path: "/new_login",
    name: "new_login",
    component: () => import('@/views/newLogin.vue'),
    meta: {
      title: "登录",
      keepAlive: true
    }
  },
  {
    path: "/page",
    name: "page",
    component: () => import('@/views/HomeView.vue'),
    meta: {
      title: "首页"
    }
  },
  {
    path: "/my",
    name: "my",
    component: () => import('@/views/My.vue'),
    meta: {
      title: "个人中心"
    }
  },
  {
    path: "/phone",
    name: "phone",
    component: () => import('@/views/PhoneBind.vue'),
    meta: {
    title: "绑定手机号"
    }
  },
  {
    path: "/phone_login",
    name: "phone_login",
    component: () => import('@/views/PhoneLogin.vue'),
    meta: {
    title: "手机号登录"
    }
  },
  {
    path: "/change_account",
    name: "account",
    component: () => import('@/views/ChangeAccount.vue'),
    meta: {
      title: "切换账号"
    }
  },
  {
    path: "/common_problem",
    name: "CommonProblem",
    component: () => import('@/views/CommonProblem.vue'),
    meta: {
      title: "常见问题"
    }
  },
  {
    path: "/problem_content/:id",
    name: "problemContent",
    component: () => import('@/views/problemContent.vue'),
    meta: {
      title: "问题详情"
    }
  },
  {
    path: "/promotion",
    name: "promotion",
    component: () => import('@/views/Promotion.vue'),
    meta: {
      title: "推广"
    }
  },
  {
    path: '/ad',
    name: "PayAd",
    component: () => import('@/views/PayAd.vue'),
    meta: {
      title: "广告"
    }
  },
  {
    path: '/ad/transition',
    name: "AdTransition",
    component: () => import('@/views/AdTransition.vue'),
    meta: {
      title: "中转页"
    }
  },
  {
    path: '/buy_steps',
    name: "BuySteps",
    component: () => import('@/views/BuySteps.vue'),
    meta: {
      title: "购买后步骤"
    }
  },
  {
    path: '/gzh_img/:from',
    name: "gzhF",
    component: () => import('@/views/gzhTransition.vue'),
    meta: {
      title: "公众号图片"
    }
  },
  {
    path: '/gzh_img',
    name: "gzh",
    component: () => import('@/views/gzhTransition.vue'),
    meta: {
      title: "公众号图片"
    }
  },
  {
    path: '/user_help',
    name: "help",
    component: () => import('@/views/GptUse.vue'),
    meta: {
      title: "新手教程"
    }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

router.beforeEach(async(to,from,next) => {

  if (!getData('original')) {
    setData("original", JSON.stringify(to.query))
  }
  let token = getLocationParams('oc-token')
  let ocapp = getLocationParams('oc-app')
  if (token && to.name != 'login') {
    next('/login?path=' + urlencode(to.fullPath))
  }
  if (ocapp) {
    setApp(ocapp)
  }
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('miniprogram') == -1 && isWeixin()) {
    next()
  } 
  
  next()
})

export default router;
