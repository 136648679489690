import { getError } from "@/api"
import Cookies from 'js-cookie';

export function isWeixin () {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}
export function isBaidu () {
  const isWebView = /swan\//.test(window.navigator.userAgent) || /^webswan-/.test(window.name)
  if(isWebView){
    return true
  }else{
    return false
  }
}
export function isMobile () {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}
export function sleep (time) {
  return new Promise(resolve => setTimeout(resolve,time))
}


         
export function urlencode(inputStr){           
  var  resultArr = [];   
  var  chars = " !\"#$ %& ' ()*+,/:;<=>?[]^`{|}~%"                
 for(var i=0;i<inputStr.length;i++){              
     var tmpChar = inputStr.charAt(i);              
     var c = inputStr.charCodeAt(i);                      
     if(c > 0x7E){                   
         resultArr[i]=encodeURI(tmpChar);             
     }else{                   
         if(tmpChar==" ")                      
             resultArr[i]="+";                   
         else if(chars.indexOf(tmpChar)!=-1)                      
             resultArr[i]="%"+c.toString(16);                   
         else                      
             resultArr[i]=tmpChar;                  
     }              
 }          
 return resultArr.join("");      
}
export function urldecode(inputStr){     
  var resultArr =[];       
  for(var i=0;i<inputStr.length;i++){     
      var chr = inputStr.charAt(i);  
      if(chr == "+"){                   
          resultArr[resultArr.length]=" ";              
      }else if(chr=="%"){                   
          var asc = inputStr.substring(i+1,i+3);   
          if(parseInt("0x"+asc)>0x7f){                       
              resultArr[resultArr.length]= decodeURI(inputStr.substring(i,i+9));                   
              i+=8;                   
          }else{                       
              resultArr[resultArr.length]=String.fromCharCode(parseInt("0x"+asc));                       
              i+=2;                   
          }              
      }else{                   
          resultArr[resultArr.length]= chr;              
      }          
  }          
  return resultArr.join("");     
} 
export function sortId(a, b) {
  return a.id - b.id;
}
// 获取路由某个参数的函数
export function getLocationParams(keyWords) {
  // 提取路由值（字符串）
  let href = window.location.href;
  // 从占位符开始截取路由（不包括占位符）
  let query = href.substring(href.indexOf("?") + 1);
  // 根据 & 切割字符串
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    // 根据指定的参数名去筛选参数值
    if (pair[0] == keyWords) {
      return pair[1];
    }
  }
  return "";
};
function getBase64Image(img) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  var dataURL = canvas.toDataURL("image/png"); // 可选其他值 image/jpeg
  return dataURL;
}

export function toBase64(src, cb) {
  var image = new Image();
  image.src = src + '?v=' + Math.random(); // 处理缓存
  image.crossOrigin = "*"; // 支持跨域图片
  image.onload = function () {
    var base64 = getBase64Image(image);
    cb && cb(base64);
  }
}
export function routeParams (url,keyWords) {
  let href = url;
  // 从占位符开始截取路由（不包括占位符）
  let query = href.substring(href.indexOf("?") + 1);
  // 根据 & 切割字符串
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    // 根据指定的参数名去筛选参数值
    if (pair[0] == keyWords) {
      return pair[1];
    }
  }
  return "";
}
export function removeDuplicate(arr) {
  const newArr = []
  arr.forEach(item => {
    if (!newArr.includes(item)) {
      newArr.push(item)
    }
  })
  return newArr
}
export function removeURLParameter(url, parameter) {
	var urlparts = url.split('?');
	if(urlparts.length >= 2) {
		//参数名前缀
		var prefix = encodeURIComponent(parameter) + '=';
		var pars = urlparts[1].split(/[&;]/g);
 
		//循环查找匹配参数
		for(var i = pars.length; i-- > 0;) {
			if(pars[i].lastIndexOf(prefix, 0) !== -1) {
				//存在则删除
				pars.splice(i, 1);
			}
		}
 
		return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
	}
	return url;
}
// 把Javascript 对象转换为键值对连接符字符串的方法

export function serialize(obj) {
  var str = [];
  for(var p in obj)
  if (obj.hasOwnProperty(p)) {
  str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
  }
  return str.join("&");
  }
// 判断在微信浏览器内
export  function isWeixinBroswer() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf("micromessenger") != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  }
 // 判断在微信小程序内
  function isMp() {
    let a = true
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
      wx.miniProgram.getEnv(function(res) {
        if (res.miniprogram) {
           a = true
        } else {  
          a =false
        }
      })
    } else {
       a = false
    };
    return a
  }
  export function keepTwoDecimal(num) {
    var result = parseFloat(num)
    if (isNaN(result)) {
      return '-'
    }
    result = Math.round(num * 100) / 100
    return result
  }
  export function blank(str) {     
    return str.replace(/\s+/g, "");
}
export function copyToClipboard(text) {
  var input = document.createElement("textarea");
  input.style.position = "fixed";
  input.style.opacity = 0;
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand("Copy");
  document.body.removeChild(input);
}
  
export function removeData(name){
  if (storage.getItem(name)) {
    storage.removeItem(name)
  }
  if (Cookies.get(name)) {
    Cookies.remove(name)
  }
}
export function clearAllData(){
  Cookies.keys().forEach(name => Cookies.remove(name))
  window.localStorage.clear()
  }
  export function getData(name) {
    if (window.localStorage.getItem(name)) {
      return window.localStorage.getItem(name)
    } else if (Cookies.get(name)) {
      return Cookies.get(name)
    } else return null
  }
  
  export function setData(name, value) {
    let storage = window.localStorage
   try {
     if (storage.getItem(name)) {
       storage.removeItem(name)
    }
     storage.setItem(name, value)
     if (Cookies.get(name)) {
       Cookies.remove(name)
     }
     Cookies.set(name, value, { expires: 365*3 ,path: "/"}); 

   } catch (error) {
    getError({'错误信息':JSON.stringify(error)},'error','存data出错'+name+value)
   }
}
export function getToken() {
  return getData('token')
}
export function setToken(token) {
  setData('token', token)
}

  export function getApp() {
    return getData('ocapp')
  }
  export function setApp(app) {
    setData('ocapp', app)
  }
  export function isminiprogram() {
    var userAgent = navigator.userAgent;
    var isMini = /miniProgram/i.test(userAgent);
    return isMini
  }