import beforeRequest from '@/utils/request'
import { version } from '../../env'
export const start_answer = (content, conversation,
  model) => {
  return beforeRequest({
    url: '/api/aichat/chat/start_answer',
    method: 'POST',
    data: {
      content,
      conversation,
      model
    },
    notLoading: true,
    params: {
      version: version
    }
  })
}
export const get_answer = (content) => {
  return beforeRequest({
    url: '/api/aichat/chat/get_answer',
    method: 'GET',
    notLoading: true,
    params: {
      version
    }
  })
}
export const answer_end = (content) => {
  return beforeRequest({
    url: '/api/aichat/chat/answer_end',
    method: 'GET',
    notLoading: true,
    params: {
      version
    }
  })
}
export const get_record = (params) => {
  return beforeRequest({
    url: '/api/aichat/chat/get_record',
    method: 'GET',
    params: {
      version
    }
  })
}
export const ip_login = origin => {
  return beforeRequest({
    url: '/api/aichat/login/login/ip',
    method: 'POST',
    data: {
      origin
    },
    params: {
      version
    },
    login: true
  })
}
// 封装获取图片验证码的接口
export const get_img_code = () => {
  return beforeRequest({
    url: '/api/aichat/login/img_code',
    method: 'GET',
    params: {
      version
    },
    login: true
  })
}
export const get_code = (phone, code, code_id, verify_status) => {
  return beforeRequest({
    url: '/api/aichat/login/phone/sent',
    method: 'POST',
    params: {
      code,
      code_id,
      version,
      verify_status
    },
    data: {
      phone
    },
    login: true

  })
}
export const get_code_pass = (phone, verify_status) => {
  return beforeRequest({
    url: '/api/aichat/login/phone/sent',
    method: 'POST',
    params: {
      version,
      verify_status
    },
    data: {
      phone
    },
    login: true

  })
}
// 封装获取图片验证码的接口
export const bind_phone = (data) => {
  return beforeRequest({
    url: '/api/aichat/user/bind_phone',
    method: 'POST',
    data,
    params: {
      version
    },
    login: true

  })
}
export const phone_login = (data) => {
  return beforeRequest({
    url: '/api/aichat/login/phone',
    method: 'POST',
    data,
    params: {
      version
    },
    login: true

  })
}
export const phone_register = (data) => {
  return beforeRequest({
    url: '/api/aichat/login/logon_phone',
    method: 'POST',
    data,
    params: {
      version
    },
    login: true
  })
}
export const createOrder = (params) => {
  return beforeRequest({
    url: '/api/aichat/order/create',
    method: 'GET',
    params: {
      version,
      ...params
    }
  })
}
export const queryOrderPay = (params) => {
  return beforeRequest({
    url: '/api/aichat/order/query',
    method: 'GET',
    params: {
      version,
      ...params
    }

  })
}
export const payClose = (params) => {
  return beforeRequest({
    url: '/api/aichat/order/close',
    method: 'GET',
    params: {
      ...params,
      version
    }
  })
}
export const check_pay_way = (arr) => {
  return beforeRequest({
    url: '/api/aichat/order/check_pay_way',
    method: 'GET',
    params: {
      pay_way_list: arr,
      version
    }
  })
}
export const get_goods = (goods_type) => {
  return beforeRequest({
    url: '/api/aichat/page_data/get_goods',
    method: 'GET',
    params: {
      goods_type: goods_type,
      version
    },
    login: true
  })
}
export const getError = (data, level, title, params) => {
  return beforeRequest({
    url: '/api/aichat/front_end/record_error',
    method: 'POST',
    data: {
      message: data,
      title,
      level
    },
    params: { ...params, version },
    login: true
  })
}
export const get_jsapi_signature = (url) => {
  return beforeRequest({
    url: "/api/aichat/login/get_jsapi_signature",
    method: "GET",
    params: {
      page_url: url,
      version
    }
  })
}
export const get_gzh_code = (params) => {
  return beforeRequest({
    url: "/api/aichat/login/get_gzh_code",
    method: "GET",
    params: {
      ...params,
      version
    },
    login: true
  })
}
export const get_weixin_lohinget = (data) => {
  return beforeRequest({
    url: "/api/aichat/login/weixin_lohinget",
    method: "POST",
    data,
    params: {
      version
    },
    login: true
  })
}
export const check_vip = (data) => {
  return beforeRequest({
    url: "/api/aichat/user/check_vip",
    method: "POST",
    data,
    params: {
      version
    },
  })
}
export const get_phone = () => {
  return beforeRequest({
    url: "/api/aichat/user/get_phone",
    method: "GET",
    params: {
      version
    }
  })
}
export const get_notice = () => {
  return beforeRequest({
    url: "/api/aichat/page_data/get_notice",
    method: "GET",
    params: {
      version
    }
  })
}
export const get_common_problem = () => {
  return beforeRequest({
    url: "/api/aichat/page_data/common_problem_list",
    method: "GET",
    params: {
      version
    }
  })
}
export const get_problem_content = (id) => {
  return beforeRequest({
    url: "/api/aichat/page_data/common_problem/content",
    method: "GET",
    params: {
      version,
      id
    }
  })
}
export const get_promotion_id = () => {
  return beforeRequest({
    url: "/api/aichat/user_promotion/get_promotion_data",
    method: "GET",
    params: {
      version,
    }
  })
}
export const get_privacy = () => {
  return beforeRequest({
    url: "/api/aichat/page_data/page_user_agreement",
    method: "GET",
    params: {
      version
    },
    login: true
  })
}
export const get_weixinmp_urlscheme = (params) => {
  return beforeRequest({
    url: "/api/aichat/admin/get_weixinmp_urlscheme",
    method: "GET",
    params: {
      ...params,
      version
    },
    login: true
  })
}
export const get_ticket = (scene_str) => {
  return beforeRequest({
    url: "/api/aichat/external/weixin/get_ticket",
    method: "GET",
    params: {
      scene_str,
      version
    },
    login: true
  })
}
export function get_conversation_list(params) {
  return beforeRequest({
    url: '/api/aichat/chat/get_conversation_list',
    method: 'get',
    params: {
      ...params,
      version
    },
    notLoading: true
  })
}
export function start_conversation(params) {
  return beforeRequest({
    url: '/api/aichat/chat/start_conversation',
    method: 'get',
    params: {
      ...params,
      version
    },
    notLoading: true
  })
}
export function get_conversation_data(params) {
  return beforeRequest({
    url: '/api/aichat/chat/get_conversation_data',
    method: 'get',
    params: {
      ...params,
      version
    },
    notLoading: true
  })
}
export function get_gzh_qr(data) {
  return beforeRequest({
    url: '/api/aichat/login/login/gzh_qr',
    method: 'post',
    params: {
      version
    },
    data: data,
    login: true

  })
}
export function check_gzh_qr_login(params) {
  return beforeRequest({
    url: '/api/aichat/login/check_gzh_qr_login',
    method: 'get',
    params: {
      ...params,
      version
    },
    notLoading: true,
    login: true

  })
}
export function stream_chat_test_new1() {
  return beforeRequest({
    url: 'api/aichat/admin/stream_chat_test_new1',
    method: "POST",
    
  })
}
export function create_image(params) {
  return beforeRequest({
    url: 'api/aichat/chat/create_image',
    method: "get",
    params
  })
}
export function get_image(id) {
  return beforeRequest({
    url: 'api/aichat/chat/get_image',
    method: "get",
    params: {
      id
    }
  })
}