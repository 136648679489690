import axios from 'axios'
import { Toast } from 'vant'
import {ip_login,get_weixin_lohinget,get_gzh_code,getError} from '@/api/index'
import { isWeixin,urlencode,isminiprogram, isWeixinBroswer,getToken,getApp, getData, setData } from './tool'
import wx from 'weixin-js-sdk'
import router from '@/router'
// import router from '@/router'
let isFirstRequest = true
const beforeRequest = function(config) {
  if (!getToken() && !config.login) {
    if (isWeixin() && !isminiprogram()) {
      router.replace('/new_login')
    } else {
      if (!router.currentRoute.path.includes('/login')) {
        router.replace('/login?path=' + urlencode(router.currentRoute.fullPath))
      }
    }
    return Promise.reject('notToken')
  } else {
    return request(config)
  }
}
const request = axios.create({
  baseURL: '/',
})
// 请求拦截器
let Config = {}
request.interceptors.request.use( async (config)=> {
    if (config.url === '/api/aichat/chat/get_answer') {
      config.timeout = 5000
    }
    Config = config
    config['headers']['oc-token'] = getToken()
    if (isWeixin()) {
      if (!isminiprogram()) {
        config['headers']['oc-app'] =  'wxweb:' + window.location.host + ':v1.0'
      } else {
        config['headers']['oc-app'] =  getApp()
      }
    } else if (getApp()) {
      config['headers']['oc-app'] =  getApp()
    } else {
      config['headers']['oc-app'] =  'h5:' + window.location.host + ':v1.0'
    }
  return config
}, function (error) {
  // 如果请求出错了（还没有发出去）会进入这里
  return Promise.reject(error)
}
)

// 响应拦截器
request.interceptors.response.use(async response => {
  const { msg, data, tip } = response.data
  if (msg === 'success') {
    if ( tip.length > 1 && !tip.includes('绑定手机成功')) {
        Toast.success({
          message: tip,
          duration: 5000
        })
    }
    return data
  } else {
    if (tip === '请登录') {
      if (getData('token')) {
            setData('token', '')
            router.push('/login?path=' + urlencode(router.currentRoute.fullPath))
          } else {
            router.push('/login?path=' + urlencode(router.currentRoute.fullPath))
          }
    } else if (tip === '正在登录的过程，请重新请求' || tip.includes('chat_response未初始化')) {
      return Promise.reject(new Error(tip))
    }
      Toast({
        message: tip,
        duration: 5000
      })
      return Promise.reject(new Error(tip))
  }
}, async error => {
  console.log(error);
  if (error.config && error.config.url === '/api/aichat/chat/get_answer'  ) {
    if (error.message === 'timeout of 5000ms exceeded') {
      return Promise.reject('获取回答接口超时')
    }
  } else {
    Toast('未知错误')
  }
  if (error.config && error.config.url === '/api/aichat/front_end/record_error') {
    return Promise.reject(error)
  } else if (error.response && error.response.status) {
      getError({'当前页面路径':window.location.href,'code': error.response.status, '接口url': error.config.url,'错误信息': JSON.stringify(error.message),'详细信息': JSON.stringify(error.response.data)},'error','接口出错')
   } else if(error.config && error.config.url){
      getError({'当前页面路径':window.location.href, '接口url': error.config.url,'错误信息': JSON.stringify(error.message)},'error','接口出错')
   }
    return Promise.reject(error)
})

export default beforeRequest
